import { Fancybox } from "@fancyapps/ui";

import { singleton } from "@solvari/utils";

import "@fancyapps/ui/dist/fancybox/fancybox.css";

const initFancybox = singleton(() => {
  Fancybox.bind("[data-fancybox]");
});

export { initFancybox };
